@import "../../theme/colors.scss";

.grid-header-container {
  margin: 0;
  width: 100%;
  .header-grid-wrapper {
    padding: 0;
    .header {
      height: 55px;
      background-color: $mineShaftGray;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      .menu-container {
        .menu {
          display: flex;
          list-style-type: none;
          position: absolute;
          left: 20px;
          top: 15px;
          .menu-item {
            margin-right: 20px;
            span {
              cursor: pointer;
              svg {
                margin-right: 10px;
              }
              color: white;
              justify-content: center;
              align-items: center;
              align-content: center;
              display: flex;
              flex-direction: row;
              text-decoration: none;
            }
            span:hover,
            span:active {
              color: $menuGreen;
              text-decoration: underline;
              svg {
                fill: $menuGreen;
              }
            }
          }
          .menu-item.active {
            span {
              color: $menuGreen;
              svg {
                fill: $menuGreen;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .logo-wrapper {
    display: none;
  }
}
