@import "../../theme/colors.scss";

.details-page {
  padding: 20px 0;
  color: $mineShaftGray;
  margin: 0 40px;
  .loading-download-container {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.7);
    z-index: 99;
    .loader {
      top: 50%;
      left: 50%;
      position: fixed;
      z-index: 999;
      transform: translate(-50%, -50%);
    }
  }

  .add-report-button {
    background-color: $persianGreen;
    color: white;
    margin-bottom: 20px;
  }
  .add-report-button:hover,
  .add-report-button:active {
    background-color: $persianGreen;
    color: white;
    margin-bottom: 20px;
  }
  .back-to-search {
    color: $havelockBlue;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .back-to-search:hover {
    text-decoration: underline;
  }
  .title-container {
    display: flex;
    .action-buttons-container {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      button {
        margin-left: auto;
        height: 40px;
        color: white;
        margin-bottom: 10px;
        width: 120px;
        svg {
          margin-right: 10px;
        }
      }
      button.save-button {
        background-color: $persianGreen;
      }
    }
  }
  .details-title {
    font-weight: normal;
    margin-bottom: 50px;
  }

  .second-title {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .details-generic {
    label {
      font-size: 12px;
      color: $emperorGray;
    }
    p {
      font-size: 14px;
    }
    p.edit-text {
      display: flex;
      align-items: center;
    }
    .edit-button {
      color: white;
      width: 50px;
      height: 25px;
      font-size: 10px;
      cursor: pointer;
      svg {
        font-size: 15px;
        margin-right: 5px;
      }
    }
    .delete-icon {
      margin: 0 20px;
      cursor: pointer;
    }
    .cancel-edit {
      display: flex;
      align-items: center;
      .edit-input {
        // margin-right: 10px;
      }
      .cancel-edit-icon {
        color: red;
        cursor: pointer;
      }
    }
  }
  .attachment-label {
    font-size: 12px;
    color: $emperorGray;
    width: 100%;
    margin-left: 10px;
  }
  .reports-grid.light-gray {
    background-color: $concreteGray;
  }
  .second-level-grid-conainter {
    margin: 0 20px;
    .image-grid-wrapper {
      position: relative;
      .delete-attachment {
        position: absolute;
        right: 20px;
        top: 20px;
        color: red;
        cursor: pointer;
      }
      .download-icon-wrapper {
        position: absolute;
        left: 0px;
        top: 0;
        cursor: pointer;
        padding: 20px;
        z-index: 2;
      }
      .video-wrapper {
        display: flex;
        cursor: pointer;
        justify-content: center;
        width: 100%;
        height: 80%;
        align-items: center;
        flex-direction: column;
        border: 1px solid $shadyGray;
        cursor: pointer;
        background-color: $lightShadyGray;
        color: $mineShaftGray;
      }
      .image-wrapper {
        cursor: pointer;
        .attachment-position-name {
          color: $emperorGray;
        }
      }
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .no-attachment {
      margin: 10px;
    }
  }

  .reports-grid {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    .label {
      font-size: 12px;
    }
    p {
      font-size: 14px;
    }
    p.error {
      width: 100%;
      color: red;
      text-align: center;
      padding: 25px;
      font-size: 16px;
    }
  }
  .delete-icon {
    cursor: pointer;
  }
  .close-add-report {
    color: red;
    position: absolute;
    right: 20px;
    font-size: 26px;
    top: 5px;
    cursor: pointer;
  }

  .save-report-button,
  .save-report-button-md-down {
    display: flex;
    margin-left: auto;
    background-color: $persianGreen;
    color: white;
    margin-bottom: 20px;
    width: 100px;
  }
  .save-report-button-md-down {
    margin-left: 0;
  }
  .save-report-button:hover,
  .save-report-button:active,
  .save-report-button-md-down:hover,
  .save-report-button-md-down:active {
    background-color: $persianGreen;
    color: white;
    margin-bottom: 20px;
  }
}
