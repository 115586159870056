@import "../../theme/colors.scss";

.filters-container {
  color: $emperorGray;
  .input-text {
    padding: 6px;
    border: 1px solid $mercuryGray;
    // color: rgba(0, 0, 0, 0.25);
    margin-left: auto;
    width: 172px;
  }
  label {
    font-size: 12px;
  }
  .filters-title {
    font-weight: normal;
    margin-bottom: 50px;
  }
  .generic-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 290px;
    input#datetime-local {
      width: 175px;
      font-size: 12px;
      // color: rgba(0, 0, 0, 0.25);
      height: 20px !important;
    }
    .label {
      // margin-right: 10px;
    }
  }
  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    transition: none;
    border-bottom: 2px solid transparent;
    pointer-events: none;
  }
  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    pointer-events: none;
    transition: none;
    border-bottom: none;
  }
  .submit-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
    .reset-button {
      display: flex;
      align-items: center;
      color: $havelockBlue;
      cursor: pointer;
      margin-right: 25px;
      .reset-icon {
        margin-right: 5px;
      }
    }
    .submit-button {
      color: white;
    }
  }
}
