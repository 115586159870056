@import "../../theme/colors.scss";

.add-submission-page {
  margin-top: 50px;
  .paper-container {
    width: 90%;
    margin: auto;
    min-height: 85vh;
    .grid-container {
      padding: 20px;
      .create-button {
        background-color: $persianGreen;
        color: white;
        font-size: 14px;
        width: 110px;
        margin-left: auto;
      }
      .back-to-search {
        color: $havelockBlue;
        cursor: pointer;
        margin-bottom: 30px;
      }
      .add-submission-generic-input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 290px;
        label,
        legend {
          font-size: 12px;
          font-family: "LynkcoType-Regular";
        }
        .label-report-type {
          span {
            font-size: 12px;
          }
        }
        .input-text {
          width: 170px;
          margin-left: auto;
        }
        .mandatory-star {
          color: red;
        }
      }
      .add-submission-title {
        font-weight: normal;
        margin-bottom: 50px;
      }
    }

    .footer-grid {
      margin-top: 50px;
      .footer {
        font-size: 14px;
        color: $emperorGray;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.MuiFormGroup-root {
  flex-direction: row;
}

.MuiFormGroup-root {
  flex-direction: row !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $mineShaftGray !important;
}
.MuiFormLabel-root {
  color: $mineShaftGray !important;
}
