@import "../../theme/colors.scss";

.MuiPaper-root.gdpr-page {
  padding: 20px;
  min-height: calc(100vh - 40px);
  .generic-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .submit-button {
    margin-left: auto;
    min-width: 110px;
    height: 35px;
    color: white;
    margin-right: 15px;
  }
  .download-as-json-button {
    background-color: $persianGreen;
    color: white;
    margin-right: 10px;
  }
  .delete-button {
    background-color: red;
    color: white;
    svg {
      margin-right: 5px;
    }
  }
  .gdpr-title {
    font-weight: normal;
    margin-bottom: 50px;
  }
  .actions-column {
    text-align: right;
  }
  .MuiTableCell-root,
  .MuiFormLabel-root,
  .MuiInputBase-input,
  .MuiPaginationItem-root,
  .MuiTypography-body1,
  .MuiButton-root,
  .MuiButton-root {
    font-family: "LynkcoType-Regular";
  }
  .add-submission-button {
    background-color: $persianGreen;
    color: white;
    margin-bottom: 20px;
  }
  .error-wrapper {
    text-align: center;
    justify-content: center;
    margin: 50px 0;
  }
  .table-container {
    max-height: 50vh;
    .table-head {
      background-color: black;
      color: white;
    }
    .submission-id {
      cursor: pointer;
      color: $havelockBlue;
    }
    .submission-id:hover,
    .submission-id:active {
      text-decoration: underline;
    }
  }
  .tab-container {
    ul {
      display: flex;
      flex-direction: row;
      li {
        color: $mediumShadyGray;
        text-align: center;
        margin-right: 25px;
        cursor: pointer;
        padding: 10px 35px;
        margin: 20px 0;
        list-style-type: none;
        border-bottom: 2px solid transparent;
      }
      li:hover {
        color: black;
        border-bottom: 2px solid black;
      }
      li.selected {
        color: black;
        border-bottom: 2px solid black;
      }
      li.no-underline-select:hover {
        color: transparent;
        border-bottom: 2px solid transparent;
      }
      li.no-underline-select.selected {
        color: black;
        border-bottom: 2px solid transparent;
      }
      .no-underline-select {
        margin-left: auto;
      }
    }
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: none;
    border-bottom: none;
    pointer-events: none;
  }
  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: none;
    border-bottom: none;
    pointer-events: none;
  }
}
