@import "../../theme/colors.scss";

.MuiPaper-root.main-page {
  padding: 20px;
  .MuiTableCell-root,
  .MuiFormLabel-root,
  .MuiInputBase-input,
  .MuiPaginationItem-root,
  .MuiTypography-body1,
  .MuiButton-root,
  .MuiButton-root {
    font-family: "LynkcoType-Regular";
  }
  .add-submission-button {
    background-color: $persianGreen;
    color: white;
    margin-bottom: 20px;
  }
  .error-wrapper {
    text-align: center;
    justify-content: center;
    margin: 50px 0;
  }
  .table-container {
    max-height: 50vh;
    .table-head {
      background-color: black;
      color: white;
    }
    .submission-id {
      cursor: pointer;
      color: $havelockBlue;
    }
    .submission-id:hover,
    .submission-id:active {
      text-decoration: underline;
    }
  }
  .pagination-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    .pagination {
      margin: 10px 0;
      margin-left: auto;
      ul {
        li {
          button {
            transition: initial;
          }
          button.Mui-selected {
            background-color: black;
            color: white;
            border-radius: 0;
          }
          button:hover,
          button:active,
          button:visited {
            background-color: black;
            color: white;
            border-radius: 0;
          }
        }
      }
    }
    .goToPage-input {
      width: 50px;
      margin: 0 10px;
      border: 1px solid $shadyGray;
      padding: 5px;
    }
    .goToPage-go-button {
      cursor: pointer;
    }
  }

  .items-per-page-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    .MuiFormLabel-root.Mui-focused {
      color: $shadyGray;
    }
    .MuiNativeSelect-select:focus {
      background-color: transparent;
    }

    .MuiFormControl-root.items-per-page-form-control {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      label + .MuiInput-formControl {
        margin-top: 0;
      }
      .MuiFormLabel-root.MuiInputLabel-root {
        position: relative;
      }
    }
    .MuiInput-underline:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      transition: none;
      border-bottom: 2px solid transparent;
      pointer-events: none;
    }
  }

  .tab-container {
    ul {
      display: flex;
      flex-direction: row;
      li {
        color: $mediumShadyGray;
        text-align: center;
        margin-right: 25px;
        cursor: pointer;
        padding: 10px 35px;
        margin: 20px 0;
        list-style-type: none;
        border-bottom: 2px solid transparent;
      }
      li:hover {
        color: black;
        border-bottom: 2px solid black;
      }
      li.selected {
        color: black;
        border-bottom: 2px solid black;
      }
      li.no-underline-select:hover {
        color: transparent;
        border-bottom: 2px solid transparent;
      }
      li.no-underline-select.selected {
        color: black;
        border-bottom: 2px solid transparent;
      }
      .no-underline-select {
        margin-left: auto;
      }
    }
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: none;
    border-bottom: none;
    pointer-events: none;
  }
  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: none;
    border-bottom: none;
    pointer-events: none;
  }
}
