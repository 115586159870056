@import "../../theme/colors.scss";

.add-report-container {
  position: relative;
  .add-report-generic {
    .input-text,
    .report-type-select,
    .textarea {
      margin: 10px 0 0 0;
    }
    .text-field {
      margin: 8px 0 0 0;
      div:first-child {
        margin-left: 0;
      }
      input {
        font-size: 12px;
      }
    }
    .textarea {
      min-height: 100px;
      resize: none;
    }
  }
}
