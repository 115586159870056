@import "../../theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: red;
  overflow: hidden;
  width: 100%;
  background: $emperorGray;
  color: white;
  font-size: 18px;
  i {
    font-style: normal;
    font-size: 200px;
  }

  .login-prompt {
    margin-top: 30px;
  }
}
